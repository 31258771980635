.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space {
  justify-content: space-between;
}
.align-items {
  align-items: center;
}
.row {
  flex-wrap: wrap;
  flex-direction: column;
}
a {
  text-decoration: none;
  color: black;
}
.each-slide {
  height: 48vh;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
}
.justify {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .content {
    padding: 10px 20px;
    padding-bottom: 20px;
  }
  .flex {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .header {
    height: auto !important;
    padding: 5px !important;
    display: flex !important;
    flex-wrap: wrap;
    flex-flow: column;
  }
  .footer {
    padding: 5px !important;
  }
  .header-menu {
    display: none;
  }
  .img-gruppe img {
    width: 100% !important;
  }
  .container-inner {
    padding: 0px !important;
    width: 100% !important;
  }
  .header-responsive {
    display: flex !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .breadcrumb {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .team-member {
    padding-bottom: 50px;
  }
  .information-footer {
    padding-bottom: 20px !important;
  }
  .footer {
    padding: 10px !important;
    text-align: center;
  }
}
