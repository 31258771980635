.header-menupoint {
  color: #7a7a7a;
  padding: 0px 20px;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.active {
  color: #144955;
  border-bottom: 1px solid #144955;
}
