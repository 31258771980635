.team-member {
  width: 300px;
}
.team-member-name {
  color: #144955;
  font-size: 25px;
}
.team-member-email {
  color: #7a7a7a;
  padding-top: 15px;
}
