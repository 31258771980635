.content {
  padding: 70px 200px;
}
.img-gruppe img {
  width: 400px;
}
.content-inner {
  padding: 0px 10px;
  text-align: justify;
}
@media screen and (max-width: 600px) {
  .content {
    padding: 10px 20px;
  }
}
