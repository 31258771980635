.header {
  background-color: white;
  height: 120px;
  padding: 10px 90px;
  border-top: 5px solid #144955;
  box-shadow: 0px 0px 13px rgb(0, 0, 0, 1);
  z-index: 1000;
}
.header-responsive {
  display: none;
}
.menu-responsive {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000000000;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  left: -100vw;
}
.menu-responsive-fadeout {
  -webkit-animation: slide-out 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide-out 0.5s forwards;
  animation-delay: 0.2s;
}
.menu-responsive-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
@-webkit-keyframes slide-out {
  100% {
    left: -100vw;
  }
}

@keyframes slide-out {
  100% {
    left: -100vw;
  }
}
